.about{
    background-color: #79E0FE;
    font-family: "Press Start 2P", system-ui;
    font-size: 24px;
    text-align: center;
    padding: 30px 0;
}

.about_button{
    background-color: #000000;
    border: none;
    border-radius: 15px;
    color: #FFFFFF;
    font-family: "Source Sans 3", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin: 0 15px;
    padding: 30px;
    width: 335px;
}

.about__content{
    font-size: 18px;
    line-height: 1.5;

    & a{
        color: #FFFFFF;
        word-wrap: break-word;
    }
}

.about__image{
    max-height: 200px;
}

.about__section{
    color: #FFFFFF;
    margin: 60px auto;
    max-width: 50%;
}

@media only screen and (max-width: 768px) {
    .about__section{
        max-width: 80%;
        padding: 5px;
    }

    .about__content{
        font-size: 16px;
    }

    .about__image{
        max-width: 100%;
    }
}