.footer {
    background-color: #453D39;
    border-top: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: "Source Sans 3", sans-serif;
    padding: 15px;
    text-align: center;
}

.footer__email{
    color: #FFFFFF;
    text-decoration: none;
}