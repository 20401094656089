.nav {
    background-color: #3FD8FF;
    display: flex;
    font-family: "Press Start 2P", system-ui;
    justify-content: space-between;
    padding: 15px;
    position: sticky;
    top: 0;
}

.nav__link{
    color: #FFFFFF;
    margin: 15px;
    text-decoration: none;
}

.nav__name{
    cursor: default;
}

@media only screen and (max-width: 768px) {
    .nav__link{
        font-size: 8px;
    }
}