.presskit {
    background-color: #79E0FE;
    font-family: "Press Start 2P", system-ui;
    font-size: 24px;
    text-align: center;
    padding: 30px 0;
}

.presskit_button{
    background-color: #453D39;
    border: none;
    border-radius: 15px;
    color: #FFFFFF;
    font-family: "Source Sans 3", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin: 0 15px;
    padding: 30px;
    width: 335px;
}

.presskit__card{
    background-color: #FFFFFF;
    border-radius: 20px;
    color: #000000;
    font-family: "Source Sans 3", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    padding: 32px;
    text-align: left;
    width: 600px;

    & a {
        color: #000000;
    }
}

.presskit__image{
    margin: 15px auto;
    max-width: 50%;
}

.presskit__link{
    font-size: 12px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    text-align: center;

    & a {
        color: #FFFFFF;
    }
}

.presskit__logo{
    margin: 15px auto;
    width: 500px;
}

.presskit__images{
    display: flex;
    flex-direction: column;
    margin: 30px 0;
}

.presskit__section{
    color: #FFFFFF;
    margin: 60px 0;
}

@media only screen and (max-width: 768px) {
    .presskit{
        font-size: 16px;
    }

    .presskit__card{
        width: 80%;
    }

    .presskit__image{
        max-width: 80%;
    }

    .presskit__logo{
        max-width: 80%;
    }
}