.home {
    background-color: #453D39;
    text-align: center;
  }

.home__banner{
    width: 100%;
}

.home__button{
    background-color: #91CEFF;
    border: none;
    border-radius: 15px;
    color: #FFFFFF;
    font-family: "Source Sans 3", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin: 0 15px;
    padding: 30px;
    width: 335px;
}

.home__button:hover{
    cursor: pointer;
    opacity: 90%;
}

.home__hero{
    width: 100%;
}

.home__presskitCTA{
    padding: 60px 0;
}

.home__kickstarterLogo{
    max-width: 30%;
    padding: 15px 0;
}

.home__story{
    color: #FFFFFF;
    font-family: "Source Sans 3", sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    padding: 60px 0;
}

.home__video{
    padding: 30px 0;
}

.home__wishlistCTA{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
    padding: 60px 0;
}

@media only screen and (max-width: 768px) {
    .home {
      display: flex;
      flex-direction: column;
    }

    .home__story{
        max-width: 80%;
    }

   .home__steamWidget{
        margin-top: 20px;
        max-width: 100%;
    }

    .home__video{
        max-width: 100%;
    }

    .home__wishlistCTA{
        flex-direction: column;
    }

  
  }